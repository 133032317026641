import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import { vw, paths, colors, genID } from '../utilities/helpers'
import SEO from '../utilities/seo'

import Block from '../components/block'
import Bit from '../components/bit'
import Glow from '../components/glow'
import Line from '../components/line'
import Hero from '../components/hero'
import Button from '../components/button'
import Parallax from '../components/parallax'
import Footer from '../components/footer'
import Text from '../components/text'

const id = genID()

const Preface = styled.section`
    margin-left: ${vw(2)};
    margin-top: ${vw(3.5)};
    width: ${vw(18)};
    position: absolute;

    h1{
        position: relative; 
        left: ${vw(-1)};
        padding-bottom: ${vw(0.5)};
    }

    p{
        padding-bottom: ${vw(0.5)};

        a{
            color: ${colors.green}
        }
    }

    svg {
        position: relative;
    }

    >section {
        width: ${vw(8)};
        margin-left: ${vw(1)};

        >div {
            margin-right: ${vw(0.25)};
        }
    }

     @media (max-width: 1023px){
        width: 90%;
        margin-left: 5%;

        > section {
            width: 50%;
        }

        p {
            padding-bottom: ${vw(1)};
        }

        h1{
            padding-bottom: ${vw(1)};
        }
     }

    @media (max-width: 767px){
        width: 80%;
        position: static;
        margin: 0 auto;

        h1{
            position: static
        } 

        p {
            padding-bottom: 48px;
        }

        > section {
            width: 100%;
            margin-left: 0;

            > div {
                margin-right: 12px;
                margin-bottom: 24px;
            }
        }
    }
`
function renderPreface(content, index) {
    const item = content.node.data

    return (
      <Block key={index} className="page-preface">
        <Preface>
          <Hero right="0" top="0">
            <img src={item.foreground.url} alt="Intergenerational dialogue" />
          </Hero>
          <section>
            <h1>
              <Text xlarge>{item.heading.text}</Text>
            </h1>
            <p>{item.preface.text}</p>
            {/* <Button target="_blank" link to="/onboard" label="Register Today" fill="#ffc452" color="#000"/> */}
            <Button
              target="_blank"
              to="https://docs.google.com/forms/d/1PYSHMu_efvpRHrHsahQ5PqWZIeSsf4cXzZ0hqJITHfE/edit?ts=6458e8d4"
              label="Register as partner"
            />
                   <Button
              target="_blank"
              to="https://docs.google.com/forms/d/1-1x_bjnkig4HUJ7Q3N9NCSJy8QpfXpUqQuwiFLWzz8k/edit?ts=6458e4c3"
              label="Register as participant"
            />
            <Line />
          </section>
        </Preface>

        <Glow royal top="-15%" left={vw(-4)} delay="0" />
        <Bit arrow top="25%" right="15%" />
        <Bit arrow top="45%" right="15%" />

        <Glow warm bottom="-50%" right={vw(-4)} delay="0.75" />
        <Bit zig top="55%" right="35%" />

        <svg viewBox="0 0 1320 720">
          <defs>
            <linearGradient
              id={id + "f"}
              gradientUnits="objectBoundingBox"
              gradientTransform="rotate(90)"
            >
              <stop offset="0" stopColor="#fff" />
              <stop offset="1" stopColor="#000" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id={id + "g"}
              gradientUnits="objectBoundingBox"
              gradientTransform="rotate(90)"
            >
              <stop offset="0" stopColor="#170a40" />
              <stop offset="1" stopColor="#4744E5" stopOpacity="0" />
            </linearGradient>
            <mask id={id + "m"}>
              <path fill={"url(#" + id + "f)"} x="100px" d={paths.a} />
            </mask>
          </defs>
          <g transform="translate(660,0)">
            <path fill={"url(#" + id + "g)"} d={paths.g} />
          </g>
          <g mask={"url(#" + id + "m)"}>
            <image
              xlinkHref={item.image.url}
              x="0"
              y="0"
              width="100%"
              height="100%"
              preserveAspectRatio="xMidYMid slice"
            ></image>
          </g>
        </svg>
      </Block>
    )
}


export default ({data}) => {

    return (
        <>
        <main>
            <SEO description={data.allPrismicPage.edges[0].node.data.preface.text} title={data.allPrismicPage.edges[0].node.data.label.text} image={data.allPrismicPage.edges[0].node.data.image.url} />

            {data.allPrismicPage.edges.map(renderPreface)}
                        
            <Parallax d/>
        </main>
        <Footer />
        </>
    )
}

export const query = graphql`
query RegisterQuery {
  allPrismicPage(filter: {uid: {eq: "register"}}) {
    edges {
      node {
        data {
          preface {
            text
          }
          foreground {
            url
          }
          image {
            url
          }
          label {
            text
          }
          heading {
            text
          }
        }
        uid
      }
    }
  }
}        
`
